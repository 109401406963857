<template>
<div class="d-flex">
  <b-input-group>
    <b-form-input v-model="propValue" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
    <b-input-group-append>
      <b-button @click="$emit('search')" variant="success"><i class="fas fa-search"></i></b-button>
    </b-input-group-append>
  </b-input-group>
  
  <b-button
    class="ml-1 d-inline-flex align-items-center"
    variant="outline-success"
    id="resetBtn"
    @click="$parent.doReset()"
  >Reset</b-button>
</div>
</template>

<script>
export default{
  props:{
    value:[String, Number]
  },
  data(){
    return {
      propValue:""
    }
  },
  mounted(){
    this.$set(this,'propValue', this.value)
  },
  watch:{
    value(){
      this.$set(this,'propValue', this.value)
    },
    propValue(){
      this.$emit('update:value', this.propValue)
    }
  }
}
</script>